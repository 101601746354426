.nav-drawer {
  position: fixed;
  inset: 0;
  margin: auto;
  z-index: var(--z-index-nav-drawer);
  background-color: var(--c-smoky-white);
  transform: translateX(100vw);
  transition-property: transform;
  transition-duration: .6s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  -webkit-overflow-scrolling: touch;
  overflow: hidden auto;
  min-height: 100%;

  @include min(tablet-portrait) {
    display: none;
  }

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }

  body.is-drawer & {
    transform: translateX(0);
  }
}
