.display-medium {
  font-weight: 800;
  max-inline-size: 25ch;
  line-height: 1.2;
  color: var(--c-black-amber);
  margin-top: 0.75em;
}

.display-small {
  font-weight: 600;
  color: var(--c-black-amber);
}

.headline-medium {
  font-weight: 600;
  color: var(--c-black-amber);
}

.title-large {
  margin-top: calc(var(--heading-line-height) / 2 * 1.5);
  font-size: 2em;
  font-weight: 600;
  color: var(--c-black-amber);
}

.body-large {
  color: var(--c-black-amber);
}
