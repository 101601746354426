.payment-brands {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px max(4vmin, 30px);

  .svg-icon {
    display: block;
    width: 120px;
    aspect-ratio: 1 / 0.54;
  }
}
