.hamburger {
  --ham-size: 50px;
  --ham-delay: 225ms;

  position: relative;
  width: var(--ham-size);
  height: var(--ham-size);
  pointer-events: auto;
  cursor: pointer;
  box-shadow: 0 0 0 1px inset var(--c-vivid-sky-blue);
  border-radius: 10px;

  @include min(tablet-landscape) {
    transform: translateX(0);
    border: 2px solid red;
  }

  &__line {
    pointer-events: none;
    position: absolute;
    inset: 0;
    margin: auto;
    transform-origin: center;
    display: block;
    width: 22px; height: 2px;
    background-color: var(--c-vivid-sky-blue);
    transition:
      transform var(--ham-delay) cubic-bezier(0.5, 0, 0, 1),
      top var(--ham-delay) cubic-bezier(0.5, 0, 0, 1) var(--ham-delay),
      bottom var(--ham-delay) cubic-bezier(0.5, 0, 0, 1) var(--ham-delay),
      width var(--ham-delay) cubic-bezier(0.5, 0, 0, 1),
      background-color var(--ham-delay) cubic-bezier(0.5, 0, 0, 1);

    &:first-child {
      width: 16px;
      top: 14px;
      transform: translateX(3px);
    }

    &:last-child {
      width: 8px;
      bottom: 14px;
      transform: translateX(7px);
    }

    body.is-drawer & {
      transition:
        transform var(--ham-delay) cubic-bezier(0.5, 0, 0, 1) var(--ham-delay),
        top var(--ham-delay) cubic-bezier(0.5, 0, 0, 1),
        bottom var(--ham-delay) cubic-bezier(0.5, 0, 0, 1),
        width var(--ham-delay) cubic-bezier(0.5, 0, 0, 1) var(--ham-delay),
        background-color var(--ham-delay) cubic-bezier(0.5, 0, 0, 1);

      &:first-child {
        width: 22px;
        top: 0;
        transform: rotate(-45deg) translateX(0);
      }

      &:last-child {
        width: 22px;
        bottom: 0;
        transform: rotate(45deg) translateX(0);
      }

      &:not(:first-child, :last-child) {
        background-color: transparent;
      }
    }
  }
}
