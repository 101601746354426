.button {
  display: grid;
  place-content: center;
  padding: 0 14px;
  font-family: inherit;
  font-size: 15px;
  background-color: var(--button-background);
  color: var(--button-color);
  width: fit-content;
  height: 48px;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  touch-action: manipulation;
  user-select: none;
  line-height: 1;
  font-weight: 600;
  letter-spacing: .1em;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  border-radius: 12.5px;

  @include min(tablet-portrait) {
    font-size: 16px;
    padding: 0 20px;
  }

  @media (hover: hover) and (pointer: fine) {
    transition: box-shadow 225ms ease-in-out, background-color 225ms ease-in-out, color 225ms ease-in-out;
  }

  &--nobg {
    --button-color: var(--c-black-amber);

    padding: 0;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        --button-color: var(--c-vivid-sky-blue);
      }
    }
  }

  &--blue {
    background: linear-gradient(0.05turn, #FA01C0, #007EFF);
    --button-color: var(--c-white);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        --button-background: var(--c-electric-blue);
      }
    }
  }

  &--outline {
    --button-color: var(--c-black-amber);

    box-shadow: 0 0 0 2px inset #007EFF;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        --button-background: var(--c-electric-blue);

        box-shadow: 0 0 0 2px inset var(--c-electric-blue);
      }
    }
  }
}
