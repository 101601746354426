.content-cards {
  gap: 20px;
  margin-top: var(--v-offset-md);

  &__item {
    display: grid;
    grid-template: auto 1fr auto / auto;
    gap: 0;
    height: 100%;
    padding: 15px;
    background-color: var(--c-dark-indigo);
    color: var(--c-white);
    border-radius: 20px;
    scroll-snap-align: center;

    .body-large {
      color: var(--c-white);
    }

    .body-medium:last-of-type {
      color: var(--c-electric-blue);

      span {
        font-weight: 700;
      }
    }
  }

  @include min(tablet-portrait) {
    display: grid;
    grid-template: 1fr / repeat(4, 1fr);
  }

  @include max(tablet-portrait) {
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    width: 100vw;
    transform: translateX(-20px);
    display: grid;
    grid-template: 1fr / repeat(4, minmax(70vw, 1fr));
    margin-bottom: -20px;
    padding: 0 20px 20px;
  }
}
