html,
body {
  position: relative;
}

body {
  overflow: hidden auto;
  background:
    radial-gradient(ellipse 40vmin 50vmin at 80% 14vmin, rgb(146 123 227 / 60%), transparent 100%),
    radial-gradient(ellipse 16vmin 16vmin at 84% 26vmin, rgb(250 55 247 / 40%), transparent 100%),
    radial-gradient(ellipse 24vmin 30vmin at 8% 56vmin, rgb(248 203 163 / 40%), transparent 100%),
    radial-gradient(ellipse 20vmin 26vmin at 88% 100vmin, rgb(247 203 247 / 40%), transparent 100%),
    radial-gradient(ellipse 20vmin 26vmin at 80% 63vmin, rgb(248 203 163 / 100%), transparent 100%),
    radial-gradient(ellipse 16vmin 16vmin at 10% 88vmin, rgb(247 203 247 / 100%), transparent 100%),
    var(--c-smoky-white);
  width: 100vw;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }
}
