.main-showcase {
  display: grid;
  grid-template: auto / auto;
  gap: 0 20px;

  @include min(tablet-portrait) {
    grid-template: auto / 1fr 250px;
  }

  &__slots {
    .button {
      width: 100%;
    }
  }

  &__winners {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template: auto 1fr / 1fr;
    gap: 0;
    padding: 0 15px 15px;
    background-color: var(--c-dark-indigo);
    border-radius: 10px;

    .title-large {
      color: var(--c-white);
    }

    &::after {
      pointer-events: none;
      content: '';
      position: absolute;
      inset: auto 0 0;
      z-index: 2;
      width: 100%;
      height: 100px;
      background: linear-gradient(transparent, var(--c-dark-indigo) 80%);
    }

    @include max(tablet-portrait) {
      display: none;
    }
  }
}
