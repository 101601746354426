.nav-main {
  .header & {
    display: grid;
    grid-template: auto / repeat(4, fit-content(100%));
    gap: 40px;
    width: fit-content;
  }

  .nav-drawer & {
    display: grid;
    grid-template: repeat(4, fit-content(100%)) / auto;
    gap: 0;
    width: fit-content;
    padding: 100px 30px 30px 20px;

    .button:not(:first-child) {
      margin-bottom: 30px;
    }
  }
}
