:root {
  // z-orders
  --z-index-header: 99;
  --z-index-nav-drawer: 98;

  // colors
  --c-white: rgb(255 255 255);
  --c-vivid-sky-blue: rgb(22 170 229);
  --c-electric-blue: rgb(90 215 255);
  --c-smoky-white: rgb(245 245 245);
  --c-dark-indigo: rgb(49 15 90);
  --c-space: rgb(65 74 76);
  --c-black-amber: rgb(3 13 16);

  // offsets
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);

  @media (width >= 992px) {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }

  // fonts
  --min-fs: 0.75;
  --max-fs: 1;
  --min-vw: 60;
  --max-vw: 160;
  --font-family-default: "Inter";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;

  // scrollbar
  --c-scrollbar-track: var(--c-bg-page);
  --c-scrollbar-thumb: var(--c-dark-orchid);
  --c-scrollbar-hover: var(--c-dark-orchid);
}
