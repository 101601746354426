.svg-icon {
  &--logo {
    width: 100px;
    aspect-ratio: 82 / 32;

    @include min(tablet-portrait) {
      width: 180px;
    }
  }
}
