.asked-questions {
  display: grid;
  gap: 20px 0;

  &__item {
    padding: 15px 20px;
    box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 0.4);
    border-radius: 20px;

    .title-large {
      margin: 0;
      padding-bottom: 10px;
      max-inline-size: none;
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.4);
    }

    .body-large {
      margin: 0;
      padding-top: 10px;
    }
  }
}
