.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &--main-bonus {
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: auto;
    overflow: hidden;
    border-radius: 20px;
  }

  &--slots {
    overflow: hidden;
    border-radius: 10px;
  }

  &--drawer-banner {
    margin: 0 auto 40px;
    width: min(500px, calc(100% - 40px));
    overflow: hidden;
    border-radius: 10px;
  }
}
