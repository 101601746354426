.main-bonuses {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 20px;
  margin-top: var(--v-offset-md);

  @include min(tablet-portrait) {
    gap: 40px;
  }

  &__item {
    position: relative;

    &-body {
      padding: 20px;
      display: grid;
      grid-template: auto auto 1fr / auto;
      gap: 10px 0;
      height: 100%;
      color: var(--c-white);

      @include min(tablet-portrait) {
        padding: 25px;
      }

      .headline-large {
        margin: 0;
        line-height: 1.2;
        font-weight: 760;
        font-size: 4.4em;
      }

      .headline-medium {
        margin: 0;
        line-height: 1;
        font-weight: 700;
        font-size: 3.4em;
        color: var(--c-white);
      }

      .headline-small {
        margin: 0;
        line-height: 1;
        font-size: 2.4em;
      }

      .button {
        margin-top: 20px;
        align-self: end;
      }
    }
  }
}
