.showcase-slots {
  display: grid;
  gap: 20px 0;
  margin-bottom: var(--v-offset-sm);

  @include min(phone-portrait) {
    grid-template: repeat(3, 1fr) / repeat(4, 1fr);
    gap: 20px;
  }
}
