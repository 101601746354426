.header {
  display: grid;
  grid-template: auto / auto 1fr auto auto;
  gap: 0;
  align-items: center;
  padding: 10px 20px 6px;
  min-height: 70px;

  @include min(tablet-portrait) {
    padding: 10px 0 6px;
    min-height: 130px;
  }

  @include max(tablet-portrait) {
    position: sticky;
    top: 0;
    z-index: var(--z-index-header);
    width: 100vw;
    transform: translateX(-20px);
    background-color: transparent;
    transition: background-color 225ms ease;

    body.is-scrolled & {
      background-color: var(--c-smoky-white);
    }
  }

  &__nav {
    justify-self: end;
  }

  &__registration {
    padding-right: 18px;
    opacity: 1;
    transition: opacity 125ms ease;

    @include min(tablet-portrait) {
      display: none;
    }

    body.is-drawer & {
      opacity: 0;
    }
  }

  &__hamburger {
    @include min(tablet-portrait) {
      display: none;
    }
  }
}
